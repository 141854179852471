
















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      item: null,
      currentTab: "",
      tabs: {
        partners: {
          label: "Quản lý đối tác",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        receive_accounts: {
          label: "Tài khoản nhận tiền",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        vnd_transactions: {
          label: "Xác nhận đối tác trả tiền VND",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        transactions: {
          label: "Xác nhận đối tác trả tiền",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        shop_wallet_transactions: {
          label: "Giao dịch trên sàn",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        statistics: {
          label: "Tổng thống kê",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
      },
    };
  },
});
